import React, { useEffect, useState } from 'react';
import {
  styled,
  Grid,
  Link,
  Container,
  useTheme,
  AppBar,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { Link as GatsbyLink } from 'gatsby';
// @ts-ignore
import LogoDark from '@/static/pollpilot-logo-dark.svg';
// @ts-ignore
import Logo from '@/static/pollpilot-logo.svg';
import { HEADER_HEIGHT, HEADER_HEIGHT_FULL } from '@/constants/layout';

const StyledImage = styled('img')`
  display: inline-block;
`;

const CustomGrid = styled(Grid)<{ $fullHeight: boolean }>`
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;

  li {
    margin-top: auto;
    margin-bottom: auto;
  }
  height: ${({ $fullHeight }) => ($fullHeight ? HEADER_HEIGHT_FULL : HEADER_HEIGHT)};
  transition: height 0.3s ease-in-out;
`;

const Navigation = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  backgroundColor: theme.palette.primaryDark[800] + 'D9',
  backdropFilter: 'blur(20px)',
  top: 0,
  right: 0,
  left: 0,
  '& ul': {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    display: 'flex',
  },
  '& li': {
    color: theme.palette.text.primary,
    ...theme.typography.body2,
    fontWeight: 700,
    '& > a, & > div': {
      display: 'inline-block',
      color: 'inherit',
      textDecoration: 'none',
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      '&:hover, &:focus': {
        backgroundColor:
          theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : theme.palette.grey[50],
        color:
          theme.palette.mode === 'dark' ? theme.palette.primaryDark[200] : theme.palette.grey[700],
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'initial',
        },
      },
    },
    '& > div': {
      cursor: 'default',
    },
  },
}));

const BannerButton = styled(Button)(({}) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  borderRadius: 0,
}));

const NavbarGrid = styled(Grid)<{ component?: string }>(({ theme }) => ({
  flexWrap: 'nowrap',
  width: 'fit-content',
  gap: theme.spacing(2),
}));

export const Header = () => {
  const theme = useTheme();
  const [navbarElevation, setNavbarElevation] = useState(0);
  useEffect(() => {
    if (!window) return;
    const handleScroll = () => {
      if (window?.scrollY > 100) {
        setNavbarElevation(5);
      } else {
        setNavbarElevation(0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      if (!window) return;
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Navigation elevation={navbarElevation}>
      <Container maxWidth="lg">
        <Box sx={{ display: { xs: 'block', sm: 'none' }, width: '100%', mb: 6 }} />
        <CustomGrid $fullHeight={navbarElevation === 0}>
          <Grid item sx={{ display: { xs: 'block', sm: 'none' }, width: '100%' }}>
            <BannerButton
              size="large"
              sx={{ m: 'auto' }}
              variant="contained"
              role="menuitem"
              onClick={() => (window.location.href = 'https://app.pollpilot.io')}
            >
              Get started for free
            </BannerButton>
          </Grid>
          <NavbarGrid component="ul" container>
            <Link component={GatsbyLink} role="menuitem" to="/">
              <StyledImage
                height="45px"
                width="45px"
                src={theme.palette.mode === 'dark' ? Logo : LogoDark}
              />
            </Link>
            <Link
              component={GatsbyLink}
              sx={{
                textDecoration: 'none',

                display: { xs: 'none', sm: 'inline-flex' },
              }}
              role="menuitem"
              to="/"
            >
              <Typography variant="h2" fontSize={24} sx={{ m: 'auto' }} fontWeight="bold">
                PollPilot
              </Typography>
            </Link>
            <li role="none">
              <Link component={GatsbyLink} sx={{ ml: 1 }} role="menuitem" to="/blog/">
                Blog
              </Link>
            </li>
            <li role="none">
              <Link component={GatsbyLink} sx={{ ml: 1 }} role="menuitem" to="/pricing/">
                Pricing
              </Link>
            </li>
          </NavbarGrid>
          <Box sx={{ display: { xs: 'none', sm: 'flex' } }} ml="auto">
            <Button
              sx={{ m: 'auto', mr: 1 }}
              role="menuitem"
              onClick={() => (window.location.href = 'https://app.pollpilot.io')}
            >
              Login
            </Button>
            <Button
              sx={{ m: 'auto' }}
              variant="contained"
              role="menuitem"
              onClick={() => (window.location.href = 'https://app.pollpilot.io')}
            >
              Get started
            </Button>
          </Box>
        </CustomGrid>
      </Container>
    </Navigation>
  );
};
