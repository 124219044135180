import React from 'react';
import { Box, Container, Grid, List, ListItem, styled, Typography } from '@mui/material';
import { Link } from 'gatsby';

const DarkFooter = styled('footer')<{ light?: boolean }>`
  background-color: ${({ theme, light }) => theme.palette.primaryDark[light ? 700 : 800]};
  padding: ${({ theme }) => theme.spacing(5)} 0;
`;

export const Footer = ({ light }: { light?: boolean }) => {
  return (
    <DarkFooter light={light}>
      <Container maxWidth="lg">
        <Grid container sx={{ flexWrap: 'nowrap' }}>
          <Grid item sm={4}>
            <Typography gutterBottom variant="h2">
              PollPilot
            </Typography>
            <Typography variant="body1">Understand your audience.</Typography>
          </Grid>
          <Grid item sx={{ mr: 0, ml: 'auto' }} sm={4}>
            <Typography variant="h6" textAlign="right">
              Company
            </Typography>
            <Box mt={2} />
            <Grid component={List} sx={{ flexDirection: 'column' }} container>
              <ListItem sx={{ ml: 'auto', mr: 0, width: 'fit-content', pr: 0 }}>
                <Typography variant="body1" color="text.primary" component={Link} to="/terms">
                  Terms
                </Typography>
              </ListItem>
              <ListItem sx={{ ml: 'auto', mr: 0, width: 'fit-content', pr: 0 }}>
                <Typography variant="body1" color="text.primary" component={Link} to="/privacy">
                  Privacy
                </Typography>
              </ListItem>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={8} />
        <Typography sx={{ width: '100%' }} textAlign="center" variant="body2">
          © 2022 PollPilot. All rights reserved.
        </Typography>
        <Box mt={5} />
      </Container>
    </DarkFooter>
  );
};
