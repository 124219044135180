import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { Box, CssBaseline } from '@mui/material';
import { ReactNode } from 'react';
import { Header } from './Header';
import { brandingDarkTheme } from '@/styles/theme';
import { Footer } from './Footer';

export const Layout = ({
  children,
  lightFooter,
}: {
  children: ReactNode;
  lightFooter?: boolean;
}) => {
  return (
    <ThemeProvider theme={brandingDarkTheme}>
      <CssBaseline />
      <Box sx={{ mt: { xs: '200px', sm: '0px' } }} />
      <Header />
      <Box sx={{ mt: '100px' }} />
      {children}
      <Footer light={lightFooter} />
    </ThemeProvider>
  );
};
